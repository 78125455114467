<template>
  <el-dialog
    :title="$t('components.dashboard_sidebar.biography')"
    :visible.sync="isVisible"
  >
    <div>
      <textarea
        v-model="bio"
        class="textarea"
        name="bio"
        rows="10"
      />
    </div>
    <div slot="footer" class="dialog-footer">
      <button class="button" @click="isVisible = false">
        Cancel
      </button>
      <button
        :loading="isUpdating"
        class="button is-primary"
        @click="updateUserBio"
      >
        Confirm
      </button>
    </div>
  </el-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      bio: this.$store.getters.dashboardUser.bio,
      isUpdating: false
    }
  },
  computed: {
    ...mapGetters({
      dashboardBioModalVisible: 'dashboardBioModalVisible',
      user: 'dashboardUser'
    }),
    isVisible: {
      get () {
        return this.dashboardBioModalVisible
      },
      set (value) {
        this.toggleModal(value)
      }
    }
  },
  methods: {
    ...mapActions({
      toggleModal: 'toggleDashboardBioModal',
      updateDashboardUserRequest: 'updateDashboardUserRequest'
    }),
    updateUserBio () {
      this.isUpdating = true
      this.updateDashboardUserRequest({ metadata: { bio: this.bio } })
        .then(response => {
          this.isUpdating = false
          this.isVisible = false
          this.$notify.success(this.$t('success.successfully_updated_user_bio'))
        })
        .catch(error => {
          this.isUpdating = false
          this.$displayRequestError(error, this.$t('errors.generic'))
        })
    }
  }
}
</script>
